

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import Choices from "choices.js";

import { financial } from '@/api/misc'
import {
    required,
} from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";


import {searchFee} from '@/api/misc'
import {getArApApi} from '@/api/cash'



export default {
    page: {
        title: "Create AP Expense ",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    validations: {
        agent_name : {required},
        charge_date : {required}
    },


    data() {
        return {
            title: "Charge Agent Fee",
            items: [
                {
                    text: "AR Agent",
                    href: "/ar/agent_bills",
                },
                {
                    text: "Charge Agent Fee",
                    href: "/ar/charge_agent_fee",
                },
            ],

            charge : {
                agent_id : '',
                amount : 0,
            },

            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },

           
            fee_choice: {},
            fee_list: [],
           
            fee_choice_dilaog: {},

          
            charge_fees   : [],

            charge_date  : '',
            current_fee_code: '',
            fee_amount   : 0,
            fee_gst      : 0,
            total_amount : 0,
            note           : '',
            agent_name     : '',

            show_mls_entry : false,
            show_error     : false,
            regbv_invoice  : '',
            regbv_address  : '',
            regbv_note     : '',
            regbv_mls_completion_date     : '',
            dropzoneOptions: {
                url: "/adm/cash/ar/batch_upload_charged_form",
                maxFilesize: 10, 
                maxFiles: 10, 
                paramName: 'form',
                thumbnailWidth: 150,
            },

            current_agent : {
                agent_name        : '',
                fee_name          : '',
                amount            : '',
                gst               : '',
                total             : '',
                note              : '',
                charge_date       : ''
            },
            edit_fee_amount   : 0,
            edit_fee_gst      : 0,
        }
    },

    components: {
        Layout,
        PageHeader,
        flatPickr,
        AgentAutoComplete,
        vueDropzone: vue2Dropzone,
        

    },

    methods: {

        formSubmit() {
           
            for (let cf of this.charge_fees) {
                if (cf.is_error) {
                    this.show_error = true
                    return
                }
            }

        
   
            let data = {
                charge_list      : this.charge_fees, 
            }
            
            getArApApi().submit_batch_form(data).then((res) => {
                if (res.errCode == 0) {
                    this.$alertify.message("Charge Fee Saved" );
                    this.$router.go()
                } else {
                this.$alertify.error("Failed to Update " + res.errCode);
                }
            })
            
        },


       

        onFormUploaded(evt, resp) {
           
            if (resp.errCode != 0) {
                this.$alertify.error("Form Format Failed ret "+resp.errCode );
            }

            
            resp.data.map(e => {
                this.charge_fees.push({
                    charge_date      : e.charge_date,
                    agent_code       : e.agent_code,
                    amount           : e.amount,
                    gst              : e.gst,
                    total            : financial(e.amount + e.gst, 2),
                    charge_code      : e.charge_code,
                    fee_name         : e.fee_name,
                    fee_code         : e.fee_code,
                    agent_id         : e.agent_id,
                   // agent_name       : e.full_name,
                    agent_name       : e.full_name_1,
                    note             : e.note,
                    err_msg          : e.err_msg,
                    is_error         : e.is_error,
                    _rowVariant      : e.is_error? 'danger' : ''

                })
            })
           
        },

      

        onSearchedFee(e) {
            searchFee({"search_str" : e.detail.value}).then((res) =>{
                this.fee_list = []
                res.data.map((f) =>{
                    this.fee_list.push({
                        label : `${f.NAME} (${f.code})`,
                        value : f.CODE,
                        fee : f
                    })
                })
                this.fee_choice.clearChoices()
                this.fee_choice.setChoices(this.fee_list)
                this.fee_choice.showDropdown()
            })
           
        },

        onAddFee() {

            this.$v.$touch();
           
            if (this.$v.$invalid == true) {
                return
            }
            let fee = this.fee_list.find((f) => f.value == this.current_fee_code)
            let regbv = {flag : false}
            if (fee.fee.code == 'OYML') {
                regbv = {
                    flag            : true,
                    invoice         : this.regbv_invoice,
                    address         : this.regbv_address,
                    completion_date : this.regbv_mls_completion_date,
                    note            : this.regbv_note
                }
            }
            this.charge_fees.push({
                agent_name    : this.agent_name,
                agent_id      : this.agent_id,
                charge_date   : this.charge_date,
                fee_code      : fee.fee.code, 
                fee_name      : fee.fee.name,
                note          : this.note,
                amount        : financial(Number(this.fee_amount), 2), 
                gst           : (Number(this.fee_gst) != 0) ? Number(this.fee_gst): 0,
                total         : financial(Number(this.fee_amount) + ((Number(this.fee_gst) != 0) ? Number(this.fee_gst): 0), 2), 
                regbv         : regbv
            })
           


        },

        onRemoveFee(data) {
            this.charge_fees.splice(data.index, 1)
         
        },

        onEdit(item, idx) {
          
            this.current_agent.agent_name       = item.agent_name
            this.current_agent.fee_name         = item.fee_name
            this.current_agent.fee_code         = item.fee_code
            this.current_agent.amount           = item.amount
            this.current_agent.gst              = item.gst
            this.current_agent.total            = item.total
            this.current_agent.note             = item.note
            this.current_agent.charge_date      = item.charge_date
            this.edit_fee_amount                = item.amount
            this.edit_fee_gst                   = item.gst

            this.current_agent.index = idx



            let fake_fee = {
                name   : this.current_agent.fee_name,
                code   : this.current_agent.fee_code,
                is_fake : true
            }
            this.fee_choice_dilaog.clearChoices()
            this.fee_choice_dilaog.setChoices([{label : `${this.current_agent.fee_name} (${this.current_agent.fee_code})`, value : this.current_agent.fee_code, selected: true, fee: fake_fee}])

           

            this.$bvModal.show('edit_dialog')
        },

        onModify() {
            let item = this.charge_fees[this.current_agent.index]
            Object.assign(item, this.current_agent)
            item.amount = financial(Number(this.edit_fee_amount), 2)
            item.gst    = financial(Number(this.edit_fee_gst), 2)
            item.total  = financial(Number(item.amount) + Number(item.gst), 2)
            item.fee_code = this.current_agent.fee_code

            let fee = this.fee_list.find(e => e.value == item.fee_code)
            if (fee) {
                item.fee_name = fee.fee.NAME
            } else {
                item.fee_name = this.current_agent.fee_name
            }
           

            this.$bvModal.hide('edit_dialog')
        },


        onSelectedAgent(agent) {
            this.agent_name = agent.label
            this.agent_id = agent.value
        },

     
        onFeeChanged() {
            this.show_mls_entry = this.current_fee_code =='OYML'? true : false
        },

        onReset() {
            this.$alertify.confirmWithTitle(
                "Confirmation",
                "Are you sure to Reset all records will be?",
                () => {
                    this.charge_fees = []
                }
            )
        },



        onSearchedFeeDialog(e) {
            searchFee({"search_str" : e.detail.value}).then((res) =>{
                this.fee_list = []
                res.data.map((f) =>{
                    this.fee_list.push({
                        label : `${f.NAME} (${f.code})`,
                        value : f.CODE,
                        fee : f
                    })
                })                
                this.fee_choice_dilaog.clearChoices()
                this.fee_choice_dilaog.setChoices(this.fee_list)
                this.fee_choice_dilaog.showDropdown()
            })
           
        },


    },

    computed: {
       
    },

    watch: {
      

        fee_amount (new_value) {
            this.fee_gst = financial(Number(new_value) * 0.05, 2)
        },

        edit_fee_amount (new_value) {
            this.edit_fee_gst = financial(Number(new_value) * 0.05, 2)
            this.current_agent.total = Number(new_value) + Number(this.edit_fee_gst)
        }
    },


    created() {
       
       

    },

    mounted() {
       
        this.fee_choice = new Choices('#choices-fee-input', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

        this.fee_choice_dilaog = new Choices('#choices-fee-input-dialog', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })
       
    }
}
</script>


<template>
    <Layout>
        <b-modal centerd id="edit_dialog" :title="'Edit Amount'"  size="lg" class="ml-3 mr-3" hide-footer :static="true">
            <div class="row mt-4">
                <div class="col-md-4">
                    <div class="mb-3">
                        <label for="choices-supplier-input" class="form-label">Agent</label>
                        <input type="text" v-model="current_agent.agent_name" class="form-control" readonly />
                    </div>
                </div>
               
                <div class="col-md-4">
                    <div class="mb-3">
                        <label for="choices-supplier-input" class="form-label">Fee</label>
                        <select class="form-control" v-model="current_agent.fee_code" name="choices-fee-input"
                            @search="onSearchedFeeDialog" id="choices-fee-input-dialog" >
                        </select>
                       
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="mb-3">
                        <label for="choices-supplier-input" class="form-label">Charge Date</label>
                        <flat-pickr v-model="current_agent.charge_date"  placeholder="Select a date" class="form-control"  ></flat-pickr>
                    </div>
                    
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-2">
                    <div class="mb-3">
                        <label for="choices-supplier-input" class="form-label">Amount</label>
                        <input type="text" v-model="edit_fee_amount" class="form-control"  />
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3">
                        <label for="choices-supplier-input" class="form-label">Gst</label>
                        <input type="text" v-model="edit_fee_gst" class="form-control"  />
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3">
                        <label for="choices-supplier-input" class="form-label">Total</label>
                        <input type="text" v-model="current_agent.total" class="form-control" readonly />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label for="choices-supplier-input" class="form-label">Note</label>
                        <input type="text" v-model="current_agent.note" class="form-control"  />
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12">
                    <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                        <b-button variant="primary" size="md" @click="$bvModal.hide('edit_dialog')">Cancel</b-button>
                        <b-button variant="primary" size="md" @click="onModify">Modify</b-button>
                    </div>
                </div>
            </div>

            
        </b-modal>

        <PageHeader :title="title" :items="items" />
        <div class="card">
            <div class="card-body">
                <form class="needs-validation" @submit.prevent="formSubmit">
                    <div class="row">
                        <div class="col-xl-12 mb-3">
                            <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"   @vdropzone-success="onFormUploaded">
                                <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop form to here.</h4>
                                </div>
                            </vue-dropzone>
                        </div>

                    </div>
                    <!-- end row-->

                    <div class="row mt-4">
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-supplier-input" class="form-label">Agent</label>
                                <AgentAutoComplete
                                    v-model="agent_name"
                                    @onSelected="onSelectedAgent"
                                    autocomplete="off"
                                    :init_data="{style_class : {'is-invalid':$v.agent_name.$error}}"
                                    :show_agent_vin="false"
                                    :show_agent_code="true"
                                />
                                <div v-if="$v.agent_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent_name.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Fee Type</label>
                                <select class="form-control" v-model="current_fee_code" name="choices-fee-input"
                                    @search="onSearchedFee" id="choices-fee-input" @change="onFeeChanged">
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-1">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Amount</label>
                                <input type="text" v-model="fee_amount" class="form-control" placeholder="$" />
                            </div>
                        </div>
                        <div class="col-sm-1">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Gst</label>
                                <input type="text" v-model="fee_gst" class="form-control" placeholder="$" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Charge Date</label>
                                <flat-pickr v-model="charge_date" ref="charge_agent_due_date" placeholder="Select a date" class="form-control" 
                                    :class="{  'is-invalid': $v.charge_date.$error}"  ></flat-pickr>
                                <div v-if="$v.charge_date.$error" class="invalid-feedback">
                                    <span v-if="!$v.charge_date.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                       

                        <div class="col-md-3">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Note</label>
                                <input type="text" v-model="note" class="form-control" placeholder="" />
                            </div>
                        </div>

                        <div class="col-md-1">
                            <div class="mb-3 mt-4">
                                <b-button variant="secondary" type="button" @click="onAddFee">Add Entry</b-button>
                            </div>
                        </div>

                    </div>
                    <!-- end row-->


                    <div class="row" :class="show_mls_entry == true?'block' : 'd-none'">
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">REGBV Invoice</label>
                                <input type="text" v-model="regbv_invoice" class="form-control" placeholder="REGBV Invoice" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">MLS Address</label>
                                <input type="text" v-model="regbv_address" class="form-control" placeholder="MLS Address" />
                            </div>
                        </div>
                        
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Completion Date</label>
                                <flat-pickr v-model="regbv_mls_completion_date"  placeholder="Select a date" class="form-control"  ></flat-pickr>
                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Note</label>
                                <input type="text" v-model="regbv_note" class="form-control" placeholder="" />
                            </div>
                        </div>

                    </div>
                    <!-- end row-->

                    <div class="row mt-5" :class="show_error?'block':'d-none'">
                        <b-alert show variant="danger">
                           
                            Form Data incorrect
                        </b-alert>
                    </div>


                    <div class="row mt-4">
                        
                        <div class="table-responsive">
                            <div class="table align-middle table-nowrap">
                                <b-table :items="charge_fees" :fields="[{ key: 'Agent' }, { key: 'Fee' },{ key: 'Amount' },{ key: 'Gst' },{ key: 'Total' },{key : 'ChargeDate'}, {key : 'Note'}, {key : 'E'},{key : 'Action'}]"
                                    responsive="sm" :per-page="charge_fees.length" class="table-check">

                                    <template #cell(Agent)="data">
                                        {{ data.item.agent_name }}
                                    </template>
                                    <template #cell(Fee)="data">
                                        {{ data.item.fee_name }}
                                    </template>
                                    <template #cell(Amount)="data">
                                        ${{ data.item.amount.toLocaleString() }}
                                    </template>
                                    <template #cell(Gst)="data">
                                        ${{ data.item.gst.toLocaleString() }}
                                    </template>
                                    <template #cell(Total)="data">
                                        ${{ data.item.total.toLocaleString() }}
                                    </template>
                                    <template #cell(ChargeDate)="data">
                                        {{ data.item.charge_date }}
                                    </template>
                                    <template #cell(Note)="data">
                                        {{ data.item.note }}
                                    </template>

                                    <template #cell(E)="data">
                                        {{ data.item.err_msg }}
                                    </template>
                                   
                                    <template #cell(Action)="data">
                                        <b-dropdown
                                            variant="white"
                                            right
                                            toggle-class="btn-link text-dark shadow-none"
                                        >
                                            <template v-slot:button-content>
                                            <i class="uil uil-ellipsis-h"></i>
                                            </template>
                                            <b-dropdown-item @click="onRemoveFee(data)">Remove</b-dropdown-item>
                                            <b-dropdown-item  @click="onEdit(data.item, data.index)">Edit</b-dropdown-item>
                                        </b-dropdown>

                                    </template>
                                </b-table>
                            </div>
                        </div>
                       
                    </div>
                    <!-- end row-->

                    <div class="row">
                        
                        <div class="col-md-12">
                            <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                                <b-button variant="secondary" size="md" @click="$router.push({name : 'ar-general'})">Cancel</b-button>
                                <b-button variant="danger" size="md" @click="onReset">Reset</b-button>
                                <b-button variant="primary" size="md" type="submit">Submit</b-button>
                            </div>
                        </div>
                        
                    </div>
                    <!-- end row-->


                </form>
            </div>
        </div>
        <!-- end card -->
    </Layout>
</template>